"use client";

import { useEffect } from "react";
import { beaconError } from "@/lib/beacon";

interface Props {
  error: Error & { digest?: string };
}

export default function GlobalError({ error }: Props) {
  useEffect(() => {
    beaconError("global-error", error).catch(() => {});
  }, [error]);

  return null;
}
